export const locale = {
  lang: 'en',
  data: {
    app: {
      vault: 'Vault',
      admin: 'Admin',
      curator: 'Curator',
      view_only: 'View Only',
      on: 'On',
      off: 'Off',
      yes: 'Yes',
      no: 'No',
      true: 'True',
      false: 'False',
      login: 'Login',
      email: 'Email',
      phone: 'Phone',
      password: 'Password',
      forget: 'forget?',
      or: 'Or',
      register: 'Register',
      help: 'Help',
      privacy_policy: 'Privacy Policy',
      copyright_policy: 'Copyright Policy',
      users: 'Users',
      manage_users: 'Manage Users',
      states: 'States',
      manage_states: 'Manage States',
      records: 'Records',
      counties: 'Counties',
      roles: 'Roles',
      billing_profiles: 'Billing Profiles',
      county: 'County',
      pdf_downloads: 'PDF Downloads',
      invoices: 'Invoices',
      index_notes: 'Index Notes',
      books: 'Books',
      book_notes: 'Book Notes',
      report_errors: 'Report Errors',
      terms_of_use: 'Terms of Use',
      save: 'Save',
      cancel: 'Cancel',
      create: 'Create',
      back: '<i class="fas fa-chevron-left"></i> Back',
      previous_page: 'Previous Page',
      retrieve: 'Retrieve',
      filter: 'Filter',
      update: 'Update',
      delete: 'Delete',
      close: 'Close',
      search: 'search...',
      name: 'Name',
      my_account: 'My Account',
      field_required: 'Required field',
      field_numbers_only: 'Only numbers allowed',
      field_price_format: 'Must be a valid price',
      field_date_format: 'Date must be in YYYY-MM-DD format',
      field_phone_format: 'Invalid phone number',
      email_required: 'Email is required',
      email_invalid: 'Must be a valid email',
      email_duplicate: 'That email is already in use',
      email_incorrect: 'No account is associated with this email',
      password_required: 'Password is required',
      password_length: 'Password must be 8 or more characters',
      change_password: 'Change Password',
      selected: 'Selected',
      select_one: 'Select one...',
      enabled: 'Enabled',
      enable: 'Enable',
      disable: 'Disable',
      logout: 'Logout',
      reset: 'Reset',
      add_instrument: 'Add Instrument',
      report_error: 'Report Error',
      browse: 'Browse...',
      loading: 'Loading...',
      loading_data: 'loading data...',
      processing: 'Processing...',
      upload: 'Upload',
      go_to_login: 'Go to login',
      download: 'Download',
      no_records_available: 'Sorry, there are currently no records available',
      bad_credentials:
        'Error logging in. You may have entered your email or password incorrectly',
      logout_error:
        'There was an error on logout. Please refresh your page and try again',
      your_account_locked: 'Your account has been locked.',
      duplicate_login:
        'Someone signed in with your email and password at another location so your session has been terminated.',
      subscription_expiration: 'Your subscription has expired',
      '404_error_header':
        '<i class="fas fa-exclamation-triangle fa-fw"></i> 404 Error',
      '404_error_description': "We're sorry, but that page does not exist.",
      go_back: '<i class="fas fa-chevron-left"></i> Go Back',
      '404_error': "The resource you've requested doesn't exist.",
      '500_error': 'There was an error. Sorry for any inconvenience.',
      expired_subscription:
        'Your subscription has expired. Please start a new subscription to proceed.',
      forced_logout_message:
        'You have been logged out so we may perform system maintenance. We apologize for any inconvenience.',
      accept_terms: 'Accept Terms',
      accept_terms_of_use: 'Accept Terms of Use',
      must_accept_terms:
        'Please read the Terms of Use and click the "Accept Terms" button to proceed.',
      change_password_success:
        'Your password was changed successfully. Please login with your new password.',
      access_prohibited:
        'You have made an attempt to access a restricted page so your session has been terminated.',
      account_activated:
        'Your account has been activated. Please log in to continue.',
      no_internet:
        'You have lost your internet connection. Please connect to the internet and try again.',
      please_select_county:
        'You must select a county before accessing the page you requested.',
      login_required: 'You must login to access the page you requested',
      please_select_state:
        'You must select a state before accessing the page you requested.',
      card_change_success: 'Your card was changed successfully',
      card_change_error: 'Error changing your card: {{error}}',
      no_search_results: 'Sorry, no records matched your search',
      inactive_logout: 'You have been logged out due to inactivity',
      page_download_generated:
        '<strong><em>{{downloadTitle}}.pdf</em></strong> is ready! Click here to download it...',
      expired_registration_token:
        'Your registration link has expired! Please <u>contact support</u> to request a new verification email.',
      '401_password_reset_token_invalid':
        'Your password reset link as expired! Please resubmit your email address on the <u>Forgot Password</u> page.',
    },
  },
};
