import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: any) {
    const exception = error.originalError || error.error || error;
    Sentry.captureException(exception);
  }
}
