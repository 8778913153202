import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';

/**
 * Configure Sentry.io
 */
import * as Sentry from '@sentry/angular';
Sentry.init({
  dsn: `https://${environment.sentryKey}@o199852.ingest.sentry.io/${environment.sentryProjectId}`,
  environment: environment.production ? 'prod' : 'dev',
  maxBreadcrumbs: 50,
  ignoreErrors: ['Non-Error exception captured'],
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
