import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Router, NavigationEnd } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { NotifierService } from 'angular-notifier-updated';

import { PageDownloadGenerated } from '@app/downloads/downloads.actions';
import { User } from './shared/users/users.models';
import * as AppActions from '@app/app.actions';

@Component({
  selector: 'vault-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public payload: any;

  @ViewChild('pdfGeneratedAlert', { static: true }) pdfGeneratedAlert;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private actions$: Actions,
    private notifier: NotifierService,
    private store: Store,
    private modalConfig: NgbModalConfig
  ) {}

  ngOnInit() {
    /**
     * Close modals on nav event
     */
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        tap((e) => this.modalService.dismissAll('NAV_EVENT'))
      )
      .subscribe();

    /**
     * Capture payload of generated PDF WS action for custom alert
     */
    this.actions$
      .pipe(ofActionSuccessful(PageDownloadGenerated))
      .subscribe(({ payload }) => {
        const user = this.store.selectSnapshot<User>((st) => st.app.user);
        if (user.id !== payload.user) {
          return;
        }
        this.payload = payload;
        this.notifier.show({
          message: '',
          type: 'success',
          template: this.pdfGeneratedAlert,
        });
      });

    /**
     * Clear spinner on page reload
     */
    this.store.dispatch(new AppActions.HideSpinner());

    /**
     * Set up modal config
     */
    this.modalConfig.animation = false;
  }
}
