import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  HostListener,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { interval, Observable } from 'rxjs';
import { throttle, withLatestFrom, map } from 'rxjs/operators';
import { Store, Select } from '@ngxs/store';
import { UntilDestroy } from '@ngneat/until-destroy';

import { AutoLogoutConfirmComponent } from './auto-logout-confirm/auto-logout-confirm.component';
import { TranslationLoaderService } from '@app/shared';
import { AppState } from '@app/app.state';
import { locale as english } from '@app/auto-logout/auto-logout.en';
import { InfoMessage, Logout } from '@app/app.actions';
import { User } from '@app/shared/users/users.models';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'vault-auto-logout',
  template: '<!--auto logout-->',
})
export class AutoLogoutComponent implements OnInit, OnDestroy {
  @Select(AppState.token) token$: Observable<string>;
  @Select(AppState.user) user$: Observable<User>;

  private activity = new EventEmitter();
  private timeoutId: any;
  private modalOpen = false;
  private modalRef: NgbModalRef;

  @Input() timeout = 15; // minutes
  @Input() interval = 1000;

  @HostListener('document:wheel', ['$event'])
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  @HostListener('document:mousedown', ['$event'])
  @HostListener('document:touchend', ['$event'])
  @HostListener('document:keypress', ['$event'])
  onActvity(event: Event) {
    this.activity.emit(event);
  }

  constructor(
    private store: Store,
    private translationLoader: TranslationLoaderService,
    private modalService: NgbModal
  ) {
    this.translationLoader.loadTranslations(english);
  }

  ngOnInit() {
    this.activity
      .pipe(
        throttle(() => interval(this.interval)),
        withLatestFrom(this.token$, this.user$),
        map(([activity, token, user]) => {
          if (user && user.$isCourthouseUser) {
            this.timeout = 60;
          }
          if (this.modalOpen) {
            this.modalRef.dismiss();
          }
          this.reset();
          if (token) {
            this.start();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    clearTimeout(this.timeoutId);
  }

  public start(): void {
    this.timeoutId = setTimeout(() => this.openModal(), this.timeout * 60000);
  }

  public reset(): void {
    clearTimeout(this.timeoutId);
  }

  public openModal(): void {
    if (this.modalOpen) {
      return;
    }
    this.modalService.dismissAll();
    this.modalOpen = true;
    this.modalRef = this.modalService.open(AutoLogoutConfirmComponent);
    this.modalRef.result.then(
      () => {
        this.modalOpen = false;
        this.store.dispatch([
          new InfoMessage('app.inactive_logout'),
          new Logout(),
        ]);
      },
      () => {
        this.modalOpen = false;
      }
    );
  }
}
