export const locale = {
  'lang': 'en',
  'data': {
    'auto_logout': {
      'continue': 'Continue',
      'confirm_continue': 'Session Expiring',
      'confirm_continue_message': 'Hit any key or move your mouse to continue your session or you will be logged out in {{timeout}} seconds.',
    }
  }
};
