import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf,
  ErrorHandler,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { throwIfAlreadyLoaded } from '@app/core/core-import-guard';

/**
 * Services
 */
import { BooksApiService } from '@app/core/services/books/books-api.service';
import { BooksDataService } from '@app/core/services/books/books-data.service';
import { DuplicateEmailValidator } from '@app/shared/validators/duplicate-email-validator';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { HttpErrorInterceptorService } from '@app/core/services/http-error-interceptor/http-error-interceptor.service';
import { LoggerService } from '@app/core/services/logger/logger.service';
import { SentryErrorHandler } from '@app/core/services/sentry-error-handler/sentry-error-handler';
import { VerifyPasswordValidator } from '@app/shared/validators/verify-password-validator';
import { VerifyEmailValidator } from '@app/shared/validators/verify-email-validator';

export const coreServices = [
  BooksApiService,
  BooksDataService,
  DuplicateEmailValidator,
  GoogleAnalyticsService,
  LoggerService,
  VerifyPasswordValidator,
  VerifyEmailValidator,
];

@NgModule({ declarations: [],
    exports: [], imports: [CommonModule,
        BrowserModule,
        BrowserAnimationsModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...coreServices,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorInterceptorService,
          multi: true,
        },
        // Duplicate? See app module providers
        {
          provide: ErrorHandler,
          useClass: SentryErrorHandler,
        },
      ],
    };
  }
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    protected googleAnalyticsService: GoogleAnalyticsService
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
