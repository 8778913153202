<router-outlet></router-outlet>

<notifier-container></notifier-container>

<vault-spinner></vault-spinner>

<vault-auto-logout></vault-auto-logout>

<ng-template #pdfGeneratedAlert let-data="payload">
	<vault-pdf-generated-alert [payload]="payload">
	</vault-pdf-generated-alert>
</ng-template>