import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule /* , PreloadAllModules */,
} from '@angular/router';

import {
  CanActivateViaAuthGuard,
  RedirectToStartPageGuard,
  CanActivateIfAdminGuard /*, RequireStateSelectedGuard */,
} from '@app/shared';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/counties',
    pathMatch: 'full',
  },
  {
    path: 'account',
    loadChildren: () =>
      import('@app/account/account.module').then((m) => m.AccountModule),
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'change_password',
    loadChildren: () =>
      import('@app/change-password/change-password.module').then(
        (m) => m.ChangePasswordModule
      ),
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'counties',
    loadChildren: () =>
      import('@app/counties/counties.module').then((m) => m.CountiesModule),
    canActivate: [
      CanActivateViaAuthGuard,
      // RequireStateSelectedGuard,
    ],
  },
  {
    path: 'downloads',
    loadChildren: () =>
      import('@app/downloads/downloads.module').then((m) => m.DownloadsModule),
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'invoices',
    loadChildren: () =>
      import('@app/invoices/invoices.module').then((m) => m.InvoicesModule),
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('@app/login/login.module').then((m) => m.LoginModule),
    canActivate: [RedirectToStartPageGuard],
    // }, {
    //   path: 'states',
    //   loadChildren: '@app/states/states.module#StatesModule',
    //   canActivate: [
    //     CanActivateViaAuthGuard,
    //   ]
  },
  {
    path: 'users',
    loadChildren: () =>
      import('@app/users/users.module').then((m) => m.UsersModule),
    canActivate: [CanActivateViaAuthGuard, CanActivateIfAdminGuard],
  },
  {
    path: 'terms_of_use',
    loadChildren: () =>
      import('@app/terms-of-service/terms-of-service.module').then(
        (m) => m.TermsOfServiceModule
      ),
  },
  {
    path: 'privacy_policy',
    loadChildren: () =>
      import('@app/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'copyright_policy',
    loadChildren: () =>
      import('@app/copyright-policy/copyright-policy.module').then(
        (m) => m.CopyrightPolicyModule
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('@app/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'forgot_password',
    loadChildren: () =>
      import('@app/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'reset_password',
    loadChildren: () =>
      import('@app/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('@app/error404/error404.module').then((m) => m.Error404Module),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
