import { NgModule, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsWebsocketPluginModule } from '@ngxs/websocket-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NotifierModule } from 'angular-notifier-updated';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from '@app/app-routing.module';
import { AutoLogoutModule } from './auto-logout/auto-logout.module';
import { CoreModule } from '@app/core/core.module';
import {
  SharedModule,
  TranslationLoaderService,
  JwtRequestInterceptorService,
  SubscriptionRequestInterceptorService,
} from '@app/shared';
import { AppComponent } from '@app/app.component';
import { LoggerService } from '@app/core';
import { AppState } from '@app/app.state';
import { customNotifierOptions } from '@app/shared/app/app.constants';
import { locale as english } from '@app/app.en';
import { StringifyProvider } from './shared/vendor/stringify/stringify.provider';

/**
 * Get GA to behave
 */
(window as any).dataLayer = (window as any).dataLayer || [];
(window as any).gtag = function () {
  (window as any).dataLayer.push(arguments);
};

@NgModule({
  imports: [
    NgbModule,
    CoreModule.forRoot(),
    TranslateModule.forRoot(),
    NgxsModule.forRoot([AppState]),
    NgxsStoragePluginModule.forRoot(),
    NgxsWebsocketPluginModule.forRoot({ url: environment.wsUrl }),
    NgxsRouterPluginModule.forRoot(),
    NotifierModule.withConfig(customNotifierOptions),
    SharedModule,
    AutoLogoutModule,
    AppRoutingModule, // load last!
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    StringifyProvider,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtRequestInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SubscriptionRequestInterceptorService,
      multi: true,
    },
  ],
})
export class AppModule {
  constructor(
    private translate: TranslateService,
    private translationLoader: TranslationLoaderService
  ) {
    /**
     * Config translation
     */
    const currentLang = this.translate.getBrowserLang();
    this.translate.addLangs([currentLang]);
    this.translate.setDefaultLang(currentLang);
    this.translate.use(currentLang);
    this.translationLoader.loadTranslations(english);
    /**
     * Init GA
     */
    if (environment.googleAnalytics) {
      this.initializeGoogleAnalytics();
    }
  }

  private initializeGoogleAnalytics() {
    try {
      (window as any).gtag('js', new Date());
      (window as any).gtag('config', environment.googleAnalytics);
    } catch (error) {
      LoggerService.warn('Error initializing google analytics');
    }
  }
}
