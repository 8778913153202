import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { AutoLogoutComponent } from './auto-logout.component';
import { AutoLogoutConfirmComponent } from './auto-logout-confirm/auto-logout-confirm.component';

@NgModule({
  imports: [SharedModule],
  declarations: [AutoLogoutComponent, AutoLogoutConfirmComponent],
  exports: [AutoLogoutComponent, AutoLogoutConfirmComponent],
})
export class AutoLogoutModule {}
